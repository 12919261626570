
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { useState } from '@/store/state';
import { useI18n } from '@/services/i18n';
import { CsStatus, HubEventName, SurveyType } from '@/models/cs/enum';
import { chatApiHandler } from '@/services/chat-api-handler';
import Cookies from 'js-cookie';
import Survey from './survey/Survey.vue';
import OfflineSurvey from './survey/OfflineSurvey.vue';
import MinimizedStatusBar from './MinimizedStatusBar.vue';
import Chatting from './chatting/Chatting.vue';
import CsNotAvailable from './chatting/CsNotAvailable.vue';
import CsDipatching from './chatting/CsDipatching.vue';
import CsFullContainer from './CsFullContainer.vue';
import RoomClosed from './chatting/RoomClosed.vue';
import BaseLoading from '../base/BaseLoading.vue';
import CsLeaved from './chatting/CsLeaved.vue';
import { SocketFactory } from '@/services/SocketFactory';
import { HubConnectionState } from '@higgs/utils';
import { Subscription } from 'rxjs';

export default defineComponent({
  name: 'ChatSystem',
  components: {
    Survey,
    Chatting,
    CsNotAvailable,
    CsDipatching,
    OfflineSurvey,
    MinimizedStatusBar,
    BaseLoading,
    CsFullContainer,
    RoomClosed,
    CsLeaved,
  },
  setup() {
    const { modules, mutation } = useState();
    const { $t, $i18n } = useI18n();
    const currentInstance = getCurrentInstance();
    const { $warn, $confirm } = currentInstance!.appContext.config.globalProperties;
    const $socketFactory: SocketFactory = currentInstance!.appContext.config.globalProperties.$socketFactory;
    const i18nLocale = computed(() => $i18n.locale);
    const chat = ref<InstanceType<typeof Chatting>>();
    const subscription = { instance: new Subscription() };
    const stopRoomSubscribed = ref<boolean>(false);
    const maintenanceSubscribed = ref<boolean>(false);
    const initQueueNumber = ref<number | null>(null);
    const status = computed(() => modules.chatting.state.status);
    const isMinimized = computed(() => modules.chatting.state.isMinimized);
    const barText = computed(() => (status.value !== CsStatus.Terminated ? 'Chat is closed' : 'Return Customer Service'));
    const isLoader = computed(() => modules.chatting.state.isLoader);
    const unReadCount = computed(() => modules.chatting.state.unReadCount);
    const chatToken = computed(() => modules.chatting.state.chatToken);
    const isRoomClosed = computed(() => status.value === CsStatus.RoomClosed);
    const isRoomTerminated = computed(() => status.value === CsStatus.Terminated);
    const isChatting = computed(() => status.value === CsStatus.Chatting || status.value === CsStatus.Terminated);
    const isShowActionBar = computed(() => !isMinimized.value && (status.value === CsStatus.Chatting || status.value === CsStatus.Terminated || isRoomClosed.value));
    const isDispatching = computed(() => status.value === CsStatus.Dispatching);
    const isCsNotAvailable = computed(() => status.value === CsStatus.CsNotAvailable);
    const isOfflineSurvey = computed(() => status.value === CsStatus.OfflineSurvey);
    const isCsLeaved = computed(() => status.value === CsStatus.SpaClosed);
    const isSurvey = computed(() => status.value === CsStatus.PreChatSurvey || status.value === CsStatus.ExitSurvey);
    const isShowMinimizedStatusBar = computed(() => (isChatting.value || isDispatching.value || isRoomTerminated.value || isRoomClosed.value) && modules.chatting.state.isMinimized);
    const isShowFullContainer = computed(() => status.value !== null && status.value !== CsStatus.LibraryClosed && !isShowMinimizedStatusBar.value);
    const haveExitSurvey = ref<boolean | null>(null);

    const onSetMinimized = (v: boolean) => {
      modules.chatting.mutation.setMinimized(v);
    };

    const checkAnyCsOnline = async () => {
      chatApiHandler.checkAnyUserOnline()
        .then(async (s) => {
          if (modules.chatting.state.isSpa) {
            const culture = Cookies.get('culture') as string;
            mutation.setCulture(culture);
          }
          modules.chatting.mutation.setMinimized(false);
          if (s) {
            if (!chatToken.value) {
              modules.chatting.mutation.setStatus(CsStatus.PreChatSurvey);
            }
          } else {
            modules.chatting.mutation.setStatus(CsStatus.CsNotAvailable);
          }
          modules.chatting.mutation.setLoader(false);
        })
        .catch(() => {
          modules.chatting.mutation.setStatus(CsStatus.CsNotAvailable);
          modules.chatting.mutation.setLoader(false);
        });
    };

    const clearSubscription = () => {
      subscription.instance.unsubscribe();
      subscription.instance = new Subscription();
      $socketFactory.ws?.off(HubEventName.StopRoomAsync);
      $socketFactory.ws?.off(HubEventName.PlayerRegressAsync);
      $socketFactory.ws?.off(HubEventName.MaintenanceAsync);
      stopRoomSubscribed.value = false;
      maintenanceSubscribed.value = false;
    };

    const checkHaveExitSurvey = async () => {
      const response = await chatApiHandler.getSurvey({ type: SurveyType.ExitSurvey, skillId: modules.chatting.state.skillId });
      haveExitSurvey.value = response.surveyQuestions !== null;
    };

    const wsEventHandler = () => {
      clearSubscription();
      // 登入後轉幣別
      subscription.instance.add(
        $socketFactory.ws?.on(HubEventName.PlayerRegressAsync).subscribe(() => {
          if (status.value === CsStatus.Chatting) {
            modules.chatting.mutation.setStatus(CsStatus.PlayerLoginAndRegressRoom);
            $socketFactory.ws?.stop();
            window.dispatchEvent(new CustomEvent('start-chat'));
          }
        }),
      );
      if (!stopRoomSubscribed.value) {
        stopRoomSubscribed.value = true;
        subscription.instance.add(
          $socketFactory.ws?.on(HubEventName.StopRoomAsync).subscribe(() => {
            const chatInstance = document.getElementById('chat-system');
            chatInstance && chatInstance.classList.remove('on-chat');
            if (status.value === CsStatus.Chatting) {
              if (haveExitSurvey.value) {
                chatApiHandler.closeRoom();
                modules.chatting.mutation.setStatus(CsStatus.ExitSurvey);
                modules.chatting.mutation.setLoader(true);
              } else {
                modules.chatting.mutation.setStatus(CsStatus.Terminated);
              }
            }
          }),
        );
      }

      if (!maintenanceSubscribed.value) {
        maintenanceSubscribed.value = true;
        subscription.instance.add(
          $socketFactory.ws?.on(HubEventName.MaintenanceAsync).subscribe(() => {
            $socketFactory.ws?.stop();
            $warn({
              title: $t('Error_Maintenance_Urgent_Notice_Title'),
              content: $t('Error_Maintenance_ChatClose_Content'),
              confirmButtonText: $t('Common_Confirm'),
            }).then(() => {
              window.location.reload();
            });
          }),
        );
      }
    };

    const onClose = () => {
      if (isRoomClosed.value) {
        modules.chatting.mutation.setStatus(CsStatus.LibraryClosed);
        return;
      }
      $confirm({
        title: $t('Common_Close_Chat_Title'),
        subTitle: '',
        content: $t('Common_Close_Chat_Content'),
        cancelButtonText: $t('Common_Cancel'),
        confirmButtonText: $t('Common_Confirm'),
        options: {
          closeOnClickModal: false,
        },
      }).then(() => {
        if (status.value !== CsStatus.Terminated) {
          chatApiHandler.closeRoom();
        }
        modules.chatting.mutation.setStatus(CsStatus.ExitSurvey);
        modules.chatting.mutation.setLoader(true);
      }).catch(() => null);
    };

    const onClickMinimizedStatusBar = () => {
      onSetMinimized(false);
      nextTick(() => {
        chat.value!.scrollToBottomSubject.next();
      });
    };

    watch(chatToken, async (token: string | null) => {
      if (token) {
        const ws = $socketFactory.build(token);
        const isConnect = await ws.start().toPromise();
        if (isConnect) {
          // 斷線後再次連線時，更新斷線期間portal未正常收到的BO訊息
          ws.stateSubject.subscribe(state => {
            if (state === HubConnectionState.Connected && chat.value) {
              chat.value.getRoomHistory();
            }
          });
          wsEventHandler();
          // spa時將api從dispatching拉出來做判斷
          // 避免在spa chatting reload時會先進到dispatching才到chatting
          if (modules.chatting.state.isSpa) {
            initQueueNumber.value = await chatApiHandler.getInitialQueueNumber();
            if (initQueueNumber.value === 0) {
              modules.chatting.mutation.setStatus(CsStatus.Chatting);
              modules.chatting.mutation.setLoader(false);
            } else {
              modules.chatting.mutation.setStatus(CsStatus.Dispatching);
            }
          } else {
            modules.chatting.mutation.setStatus(CsStatus.Dispatching);
          }
        } else {
          modules.chatting.mutation.setStatus(CsStatus.LibraryClosed);
          modules.chatting.mutation.setLoader(false);
        }
        checkHaveExitSurvey();
      }
    });

    watch(status, (v: CsStatus | null) => {
      if (v === CsStatus.LibraryClosed || v === CsStatus.Terminated || v === CsStatus.ExitSurvey) {
        if (v === CsStatus.LibraryClosed) {
          modules.chatting.mutation.setMinimized(true);
        }
        chatApiHandler.removeToken();
        modules.chatting.mutation.setChatToken('');
        $socketFactory.ws?.stop();
      }
    }, { immediate: true });

    onUnmounted(() => {
      clearSubscription();
    });

    return {
      ...modules,
      chat,
      status,

      isMinimized,
      barText,
      isLoader,
      unReadCount,
      isShowFullContainer,
      initQueueNumber,

      isRoomClosed,
      isChatting,
      isShowActionBar,
      isDispatching,
      isCsNotAvailable,
      isOfflineSurvey,
      isCsLeaved,
      isSurvey,
      isShowMinimizedStatusBar,

      onSetMinimized,
      checkAnyCsOnline,
      onClose,
      onClickMinimizedStatusBar,
      i18nLocale,
    };
  },
  async created() {
    // 重整後先確定有沒有token
    const result = await chatApiHandler.checkPlayerInChat();
    if (result.data && result.data.token) {
      this.onSetMinimized(!this.chatting.state.isSpa);
      this.chatting.mutation.setRoomId(result.data.roomId);
      this.chatting.mutation.setSkillId(result.data.skillId);
      this.chatting.mutation.setChatToken(result.data.token);
    }
    if (result.statusCode === 'PLAYER-10407') {
      this.onSetMinimized(!this.chatting.state.isSpa);
      this.chatting.mutation.setStatus(CsStatus.RoomClosed);
    }
    if (this.chatting.state.isSpa) {
      this.chatting.mutation.setLoader(true);
      if (!this.chatting.state.chatToken) {
        // 沒token的話就開room 有token就略過直接進到pending or chatting
        this.checkAnyCsOnline();
      }
    }
    // 按下開始聊天
    window.addEventListener('start-chat', () => {
      // 顯示蓋版loader轉圈動畫
      this.chatting.mutation.setLoader(true);
      if (this.isChatting || this.chatting.state.chatToken) {
        // 如果已經有在聊天就把聊天蓋板畫面打開
        this.chatting.mutation.setMinimized(false);
        this.chatting.mutation.setLoader(false);
      } else {
        this.checkAnyCsOnline();
      }
    });
    window.addEventListener('kick-out', () => {
      chatApiHandler.removeToken();
    });
  },
});
